import { Component, Vue } from 'vue-property-decorator'
import Prestations from '@/views/Patients/Prescriptions/Prestations/Prestations.vue'
import Prestation from '@/views/Patients/Prescriptions/Prestation/Prestation.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import { PrescriptionHeader } from '../../Static'
import { PrescriptionModel } from '@/models/prescriptions-model'
import Commons from '@/components/shared/Helpers/commons'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { ErrorService } from '@/services/error.service'
import { getPrescriptionStatusText } from '../../Dossier/Constants'
import { AxiosResponse } from 'axios'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    Confirm,
    Alert,
    Prestations,
    Prestation
  }
})
export default class Prescriptions extends Vue {
  private prescriptionService = PatientPrescriptionService.getInstance()
  private selectedPrescription: PrescriptionModel = {}
  private selectedDossier!: dossierPatientModel

  public headers = PrescriptionHeader
  public apiURL = process.env.VUE_APP_API_URL
  public prescriptions: PrescriptionModel[] = []
  public selectedRowItemId = ''
  public selectedRow: PrescriptionModel[] = []
  public errorMessages: string[] = []
  public isLoading = false
  public showDialog = false
  public showConfirm = false
  private allowPdf: number[] = [1, 2, 3, 4, 5]
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier') as string
    this.selectedDossier = JSON.parse(cachedDossier)
    if (this.selectedDossier?.guid) {
      Commons.updateDossierName()
      this.getPrescriptions()
    } else {
      this.$router.push('/patient')
    }
  }

  private async getPrescriptions () {
    this.isLoading = true
    const response = await this.prescriptionService.GetDossierPrescriptions(this.selectedDossier.guid)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.isLoading = false
      })
    this.prescriptions = response as PrescriptionModel[]
    Commons.setRowClasses(this.prescriptions)
  }

  public getStatusText (id: number) {
    return getPrescriptionStatusText(id)
  }

  public addPrescription () {
    this.prescriptionService.updatePrescriptionSelected({ date: Commons.GetTodayFormatted(), statusId: 1 })
    const path = `/patient/${this.selectedDossier.guid}/prescription/new`
    this.$router.push(path)
  }

  public editPrescription (prescription: PrescriptionModel) {
    this.prescriptionService.updatePrescriptionSelected(prescription)
    const path = `/patient/${this.selectedDossier.guid}/prescription/${prescription.id}`
    this.$router.push(path)
  }

  public displayDeleteConfirmDialog (prescription: PrescriptionModel) {
    this.showConfirm = true
    this.selectedPrescription = prescription
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      const results = await this.prescriptionService.deletePrescription(this.selectedPrescription.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })

      if ((results as AxiosResponse<number>)?.status === 200) {
        this.errorMessages = []
        this.getPrescriptions()
      }
    }
  }

  public async getDocumentById (documentId: string) {
    return await this.prescriptionService.getDocumentURL(documentId)
  }

  public showPrestationDialog (rowId: string) {
    this.prescriptionService.updateprestationDialogModelSource(true)
    const row = this.prescriptions.find(x => x.id === rowId) as PrescriptionModel
    this.prescriptionService.updatePrescriptionSelected(row)
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public goHome () {
    this.$router.push('/patient')
  }

  public async generatePDFReport (item: PrescriptionModel) {
    item.date = item.date?.substr(0, 10)
    this.prescriptionService.updatePrescriptionSelected(item)
    this.$router.push({ name: 'PrescriptionReport', params: { id: item.id! } })
  }

  public cangenratePdf (ids) {
    return this.allowPdf.some(x => ids.includes(x))
  }
}
