var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"main-container",attrs:{"fluid":""}},[_c('div',{staticClass:"page-title"},[_c('h2',[_vm._v("Prescriptions")])]),_c('alert',{attrs:{"type":"error","canClose":true,"value":_vm.errorMessages.length > 0},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)]),_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":"Supprimer cette prescription et les prestations qui y sont liées","message":"Toute prestation liée sera également supprimée. <br/>Cette action ne peut être annulée. Voulez-vous continuer et supprimer cette prescription?"},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}}),_c('c-btn',{staticClass:"btn-red",attrs:{"loading":_vm.isLoading},on:{"click":function($event){return _vm.addPrescription()}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Ajouter une nouvelle prescription")])],1),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.prescriptions,"hide-default-footer":_vm.prescriptions.length <= 10,"sort-by":['date', 'status'],"sort-desc":[true, false],"show-expand":"","expanded":_vm.prescriptions,"item-key":"id","mobile-breakpoint":600,"values":_vm.selectedRow,"item-class":"rowClass","items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll }},scopedSlots:_vm._u([{key:"item.statusId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusText(item.statusId))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-fr")(item.date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.cangenratePdf(item.prestationTypeIds))?_c('v-icon',_vm._g(_vm._b({staticClass:"action-doc",on:{"click":function($event){return _vm.generatePDFReport(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-star-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Générer le document de prescription médicale pour l'enseignement diab/diét. et les prestations de soins")])]),(item.documentId)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{directives:[{name:"auth-href",rawName:"v-auth-href",value:({downloadingText: 'chargement en cours', overrideInnerHtml: false}),expression:"{downloadingText: 'chargement en cours', overrideInnerHtml: false}"}],attrs:{"href":(_vm.apiURL + "/dossierPatient/document/" + (item.documentId)),"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({staticClass:"action-doc"},'v-icon',attrs,false),on),[_vm._v(" mdi-file-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir le document d'origine")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.editPrescription(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),(!item.isLinkedToInvoice)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){return _vm.displayDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('prestations',{attrs:{"prescriptionIdProp":item.id}}),_c('div',{staticClass:"table-btn-box"},[_c('c-btn',{staticClass:"btn-white",on:{"click":function($event){return _vm.showPrestationDialog(item.id)}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Ajouter une nouvelle prestation")])],1)],1)],1)]}}],null,true)}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('c-btn',{staticClass:"btn-white",on:{"click":function($event){return _vm.goHome()}}},[_c('span',[_vm._v(" Retour à la liste des patients ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }